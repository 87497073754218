import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Inventory,
  InventoryState,
} from "@/models/interfaces/material/inventory.interface";
import { InventoryService as service } from "@/services/material/inventory.services";
import { constants } from "@/constants/material/inventory";

export const actions: ActionTree<InventoryState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Inventory[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_INVENTORIES, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Inventory) => {
      commit(constants.SET_INVENTORY, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Inventory[] = await service.insert(state.inventory);
    commit(constants.INSERT_INVENTORY, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Inventory[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_INVENTORIES, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Inventory[] = await service.update(id, state.inventory);
    commit(constants.UPDATE_INVENTORY, res);
  },
};
