import module00 from "./00.service";
import module02 from "./02.service";
import module10 from "./10.service";
import module15 from "./15.service";
import module20 from "./20.service";
import module30 from "./30.service";
import module40 from "./40.service";
import module41 from "./41.service";
import module50 from "./50.service";
import module51 from "./51.service";
import module53 from "./53.service";
import module60 from "./60.service";
import module61 from "./61.service";
import module70 from "./70.service";
import module90 from "./90.service";
import module101 from "./101.service";
import module102 from "./102.service";
import module103 from "./103.service";
import module201 from "./201.service";
import module202 from "./202.service";
import module203 from "./203.service";
import module300 from "./300.service";
import module400 from "./400.service";
import module500 from "./500.service";
import module900 from "./900.service";

const moduleMap: { [key: string]: any } = {
  "00": module00,
  "02": module02,
  "10": module10,
  "15": module15,
  "20": module20,
  "30": module30,
  "40": module40,
  "41": module41,
  "50": module50,
  "51": module51,
  "53": module53,
  "60": module60,
  "61": module61,
  "70": module70,
  "90": module90,
  "101": module101,
  "102": module102,
  "103": module103,
  "201": module201,
  "202": module202,
  "203": module203,
  "300": module300,
  "400": module400,
  "500": module500,
  "900": module900,
};

export const getJson = async (
  product: any,
  valor: any,
  cenarioIcms: any,
  taxation: any,
  recipient: any,
  consumidorFinal: any
) => {
  const cst = cenarioIcms.Cst.toString();
  try {
    return moduleMap[cst]({
      product,
      valor, // antigo item
      cenarioIcms,
      taxation,
      recipient,
      consumidorFinal,
    });
  } catch (error) {
    console.error(`Erro ao carregar o módulo para o ID ${cst}:`, error);
    return null;
  }
};
