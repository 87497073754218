import { RouteRecordRaw } from "vue-router";
import Customer from "../../views/customer/Index.vue";
import Customers from "../../views/customer/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/customer/:id",
  name: "Customer",
  component: Customer,
  beforeEnter: Auth,
});
itens.push({
  path: "/customer/list",
  name: "Customers",
  component: Customers,
  beforeEnter: Auth,
});

export default itens;
