import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  BusinessGroup,
  BusinessGroupState,
} from "@/models/interfaces/administration/businessgroup.interface";
import { BusinessGroupService as businessgroup } from "@/services/administration/businessgroup.services";
import { constants } from "@/constants/administration/businessgroup";

export const actions: ActionTree<BusinessGroupState, RootState> = {
  getAll({ commit }): void {
    businessgroup.getAll().then((res: BusinessGroup[]) => {
      commit(constants.SET_BUSINESSGROUPS, res);
    });
  },
  get({ commit }, id): void {
    businessgroup.get(id).then((res: BusinessGroup) => {
      commit(constants.SET_BUSINESSGROUP, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: BusinessGroup[] = await businessgroup.insert(
      state.businessGroup
    );
    commit(constants.INSERT_BUSINESSGROUP, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await businessgroup.remove(ids);
    const res: BusinessGroup[] = await businessgroup.getAll();
    commit(constants.SET_BUSINESSGROUPS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: BusinessGroup[] = await businessgroup.update(
      id,
      state.businessGroup
    );
    commit(constants.UPDATE_BUSINESSGROUP, res);
  },
};
