import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  SupplierType,
  SupplierTypeState,
} from "@/models/interfaces/material/suppliertype.interface";
import { SupplierTypeService as service } from "@/services/material/supplierType.services";
import { constants } from "@/constants/material/suppliertype";

export const actions: ActionTree<SupplierTypeState, RootState> = {
  getAll({ commit }): void {
    service.getAll().then((res: SupplierType[]) => {
      commit(constants.SET_SUPPLIERTYPES, res);
    });
  },
  get({ commit }, id): void {
    service.get(id).then((res: SupplierType) => {
      commit(constants.SET_SUPPLIERTYPE, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: SupplierType[] = await service.insert(state.supplierType);
    commit(constants.INSERT_SUPPLIERTYPE, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);
    const res: SupplierType[] = await service.getAll();
    commit(constants.SET_SUPPLIERTYPES, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: SupplierType[] = await service.update(id, state.supplierType);
    commit(constants.UPDATE_SUPPLIERTYPE, res);
  },
};
