import { API_URL } from "@/constants/env";
import { ProductOrigin } from "@/models/interfaces/shared/productorigin.interface";
import axios from "axios";

const getAll = async (): Promise<ProductOrigin[]> => {
  const r = (await axios.get(`${API_URL}/shared/productorigin`)).data;
  return r;
};

const get = async (id: string): Promise<ProductOrigin> => {
  return (await axios.get(`${API_URL}/shared/productorigin/${id}`)).data;
};

export const ProductOriginService = {
  getAll,
  get,
};
