import {
  Product,
  ProductState,
  ProductStore,
} from "@/models/interfaces/material/product.interface";

const product: Product = {
  /* product */
  id: "",
  code: "",

  productypeId: undefined,
  brandId: undefined,
  supplierId: undefined,
  supplierCnpj: "",
  supplierCorporatename: "",
  supplierFantasyname: "",
  userId: undefined,

  /* historic product */
  description: "",
  ncm: "",
  cest: "",
  ean: "",
  price: "",
  discountinreais: "",
  useserialnumber: true,
  movesstock: true,
  returnstostock: true,
  note: "",
  unitofmeasurementId: undefined,
  productoriginId: undefined,
  productgenreId: undefined,

  /* historic product */
  conversionsunitofmeasure: [],
};

const products: Array<Product> = [];

const productStore: ProductStore = {
  amount: 0,
  description: "",
  id: 0,
  idProduct: "",
  name: "",
  serial: "",
  value: 0,
  maxdiscount: 0,
};

const productsStore: Array<ProductStore> = [];

export const state: ProductState = {
  product,
  products,
  productStore,
  productsStore,
};
