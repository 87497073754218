import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  RequestingPhysician,
  RequestingPhysicianState,
} from "@/models/interfaces/billing/requestingphysician.interface";
import { RequestingPhysicianService as requestingPhysician } from "@/services/billing/requestingphysician.services";
import { constants } from "@/constants/billing/requestingphysician";

export const actions: ActionTree<RequestingPhysicianState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: RequestingPhysician[] = await requestingPhysician.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_REQUESTINGPHYSICIANS, res);
  },
  get({ commit }, id): void {
    requestingPhysician.get(id).then((res: RequestingPhysician) => {
      commit(constants.SET_REQUESTINGPHYSICIAN, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: RequestingPhysician[] = await requestingPhysician.insert(
      state.requestingphysician
    );
    commit(constants.INSERT_REQUESTINGPHYSICIAN, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await requestingPhysician.remove(ids);
    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: RequestingPhysician[] = await requestingPhysician.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_REQUESTINGPHYSICIANS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: RequestingPhysician[] = await requestingPhysician.update(
      id,
      state.requestingphysician
    );
    commit(constants.UPDATE_REQUESTINGPHYSICIAN, res);
  },
};
