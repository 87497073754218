import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { DailyCashFlow } from "@/models/interfaces/financial/cashmanagement.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "caixa").fetchData();

const get = async (id: string): Promise<DailyCashFlow> => {
  if (id == "new") {
    const entity: DailyCashFlow = {
      id: "0",
      dateofcompetence: "",
      openingvalue: 0,
      closingdate: undefined,
      responsibleuserId: undefined,
      userresponsibleclosingId: undefined,
      moneylaunch: [],
    };
    return entity;
  }
  const url = `${API_URL}/caixa?id=${id}`;
  console.log(url);
  const entity = (await axios.get(url)).data;

  return treatedEntity(entity);
};

const insert = async (entity: DailyCashFlow): Promise<DailyCashFlow[]> => {
  const a = (await axios.post(`${API_URL}/caixa/Salvar`, prepareData(entity)))
    .data;

  store.state.login.credential.cashier = a.Id;

  return [];
};

const remove = async (id: string): Promise<DailyCashFlow[]> => {
  const credential = { ...store.state.login.credential };

  return (
    await axios.delete(
      `${API_URL}/caixa/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (
  id: string,
  entity: DailyCashFlow
): Promise<DailyCashFlow[]> => {
  return (await axios.post(`${API_URL}/caixa/Salvar`, prepareData(entity)))
    .data;
};

const prepareData = (entity: DailyCashFlow) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...updateEntity } = entity;
  const credential = { ...store.state.login.credential };

  const Id = id ? id : 0;

  const IdCliente = credential.tenantId;
  const usuario = credential.userId;
  const ProfissionalId = credential.userId;

  return { Id, IdCliente, usuario, ProfissionalId, ...updateEntity };
};

const treatedEntity = (entity: any): any => {
  if (entity.DataCompetenciaFormatada) {
    const parts = entity.DataCompetenciaFormatada.split("/");
    const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    entity.DataCompetenciaFormatada = new Date(isoDateString)
      .toISOString()
      .split("T")[0];
  }

  return entity;
};

export const DailyCashFlowService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
