import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Checklist,
  ChecklistState,
} from "@/models/interfaces/commercial/checklist.interface";
import { ChecklistService as checklist } from "@/services/commercial/checklist.services";
import { constants } from "@/constants/commercial/checklist";

export const actions: ActionTree<ChecklistState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Checklist[] = await checklist.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_CHECKLISTS, res);
  },
  get({ commit }, id): void {
    checklist.get(id).then((res: Checklist) => {
      commit(constants.SET_CHECKLIST, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Checklist[] = await checklist.insert(state.checklist);
    commit(constants.INSERT_CHECKLIST, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await checklist.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Checklist[] = await checklist.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_CHECKLISTS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Checklist[] = await checklist.update(id, state.checklist);
    commit(constants.UPDATE_CHECKLIST, res);
  },
};
