import { useDate } from "@/composables/date";
import { API_URL } from "@/constants/env";
import { InvoiceProduct } from "@/models/interfaces/commercial/invoiceproduct.interface";
import store from "@/store";
import axios from "axios";

export const get = async (id: string): Promise<InvoiceProduct> => {
  const credential = { ...store.state.login.credential };

  if (id == "new") {
    const entity: InvoiceProduct = {
      id: "",
      ChaveAcesso: "",
      nfeitem: [],
      CfopId: 0,
      ClienteId: 0,
      Cnpj: credential.storeCnpj,
      ConsumidorFinal: 1,
      DataEmissaoFormatada: useDate("pt-BR").today().format,
      FinalidadeOperacao: 1,
      Id: 0,
      IdCliente: credential.tenantId,
      Natureza: "VENDA",
      NumeroNf: 0,
      Observacao: "",
      Presencial: 1,
      Saida: 1,
      StatusNfe: 1,
      TipoCliente: 1,
      TipoNf: 2,
      Usuario: credential.userId,
      CenarioImpostosId: 0,
    };
    return treatedEntity(entity);
  }

  const entity = (await axios.get(`${API_URL}/nfe?id=${id}`)).data;

  entity.nfeitem = (
    await axios.get(`${API_URL}/nfe/getitens?nfe=${entity.Id}`)
  ).data;

  return treatedEntity(entity);
};

const treatedEntity = (entity: any): any => {
  if (entity.DataEmissaoFormatada) {
    const parts = entity.DataEmissaoFormatada.split("/");
    const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    entity.DataEmissaoFormatada = new Date(isoDateString)
      .toISOString()
      .split("T")[0];
  }

  return entity;
};
