import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  StockTransfer,
  StockTransferState,
} from "@/models/interfaces/material/stocktransfer.interface";
import { StockTransferService as stockTransfer } from "@/services/material/stocktransfer.services";
import { constants } from "@/constants/material/stocktransfer";

export const actions: ActionTree<StockTransferState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: StockTransfer[] = await stockTransfer.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_STOCKTRANSFERS, res);
  },
  get({ commit }, id): void {
    stockTransfer.get(id).then((res: StockTransfer) => {
      commit(constants.SET_STOCKTRANSFER, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: StockTransfer[] = await stockTransfer.insert(
      state.stockTransfer
    );
    commit(constants.INSERT_STOCKTRANSFER, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await stockTransfer.remove(ids);
    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: StockTransfer[] = await stockTransfer.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_STOCKTRANSFERS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: StockTransfer[] = await stockTransfer.update(
      id,
      state.stockTransfer
    );
    commit(constants.UPDATE_STOCKTRANSFER, res);
  },
};
