import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Brand,
  BrandState,
} from "@/models/interfaces/material/brand.interface";
import { BrandService as service } from "@/services/material/brand.services";
import { constants } from "@/constants/material/brand";

export const actions: ActionTree<BrandState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Brand[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_BRANDS, res);
  },

  getBySupplierCore({ commit }, id): void {
    service.getBySupplierCore(id).then((res: Brand[]) => {
      commit(constants.SET_BRANDS, res);
    });
  },
  get({ commit }, id): void {
    service.get(id).then((res: Brand) => {
      commit(constants.SET_BRAND, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Brand[] = await service.insert(state.brand);
    commit(constants.INSERT_BRAND, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Brand[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_BRANDS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Brand[] = await service.update(id, state.brand);
    commit(constants.UPDATE_BRAND, res);
  },
};
