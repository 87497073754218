import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  PartnershipReport,
  PartnershipReportState,
} from "@/models/interfaces/medicalconsultation/partnershipreport.interface";
import { PartnershipReportService as partnershipReport } from "@/services/medicalconsultation/partnershipreport.services";
import { constants } from "@/constants/medicalconsultation/partnershipreport";

export const actions: ActionTree<PartnershipReportState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: PartnershipReport[] = await partnershipReport.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_PARTNERSHIPREPORTS, res);
  },
  get({ commit }, id): void {
    partnershipReport.get(id).then((res: PartnershipReport) => {
      commit(constants.SET_PARTNERSHIPREPORT, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: PartnershipReport[] = await partnershipReport.insert(
      state.partnershipreport
    );
    commit(constants.INSERT_PARTNERSHIPREPORT, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await partnershipReport.remove(ids);
    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: PartnershipReport[] = await partnershipReport.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_PARTNERSHIPREPORTS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: PartnershipReport[] = await partnershipReport.update(
      id,
      state.partnershipreport
    );
    commit(constants.UPDATE_PARTNERSHIPREPORT, res);
  },
};
