import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  ConsultationGuide,
  ConsultationGuideState,
} from "@/models/interfaces/billing/consultationguide.interface";
import { ConsultationGuideService as service } from "@/services/billing/consultationguide.services";
import { constants } from "@/constants/billing/consultationguide";

export const actions: ActionTree<ConsultationGuideState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: ConsultationGuide[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_CONSULTATIONGUIDES, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: ConsultationGuide) => {
      commit(constants.SET_CONSULTATIONGUIDE, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: ConsultationGuide[] = await service.insert(
      state.consultationGuide
    );
    commit(constants.INSERT_CONSULTATIONGUIDE, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);
    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: ConsultationGuide[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_CONSULTATIONGUIDES, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: ConsultationGuide[] = await service.update(
      id,
      state.consultationGuide
    );
    commit(constants.UPDATE_CONSULTATIONGUIDE, res);
  },
};
