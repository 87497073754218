import { API_URL } from "@/constants/env";
import {
  Product,
  ProductStore,
} from "@/models/interfaces/material/product.interface";
import axios from "axios";
import store from "@/store";
import { useGetAll } from "@/composables/getall";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "produtoconf").fetchData();

const get = async (id: string): Promise<Product> => {
  if (id == "new") {
    const entity: Product = {
      /* product */
      id: "",
      code: "",

      productypeId: undefined,
      brandId: undefined,
      supplierId: undefined,
      supplierCnpj: "",
      supplierCorporatename: "",
      supplierFantasyname: "",
      userId: undefined,

      /* historic product */
      description: "",
      ncm: "",
      cest: "",
      ean: "",
      price: "",
      discountinreais: "",
      useserialnumber: true,
      movesstock: true,
      returnstostock: true,
      note: "",
      unitofmeasurementId: undefined,
      productoriginId: undefined,
      productgenreId: undefined,

      /* historic product */
      conversionsunitofmeasure: [],
    };

    return entity;
  }

  const entity = (await axios.get(`${API_URL}/material/product/${id}`)).data;

  const {
    useserialnumber,
    movesstock,
    returnstostock,
    userId,
    ...updateEntity
  } = entity;

  const treatedEntity = {
    ...updateEntity,
    useserialnumber: useserialnumber == 1,
    movesstock: movesstock == 1,
    returnstostock: returnstostock == 1,
    userId,
  };

  if (treatedEntity.conversionunitofmeasure?.length)
    treatedEntity.conversionunitofmeasure[0].show = true;

  return treatedEntity;
};

const getById = async (id: string): Promise<Product> => {
  const entity = (await axios.get(`${API_URL}/produtoconf/getid?id=${id}`))
    .data;

  return entity;
};

const getByDescriptionSerialForAutoComplete = async (
  filter: string
): Promise<any[]> => {
  if (filter.length > 2) {
    const credential = { ...store.state.login.credential };
    console.log(
      `${API_URL}/produtoconf/getestoqueporprodutoserie?cliente=${credential.tenantId}&unidade=${credential.storeId}&filtro=${filter}`
    );
    const list: Array<ProductStore> = (
      await axios.get(
        `${API_URL}/produtoconf/getestoqueporprodutoserie?cliente=${credential.tenantId}&unidade=${credential.storeId}&filtro=${filter}`
      )
    ).data;
    console.log(list);
    const products: ProductStore[] = list.map((item: ProductStore) => {
      const description = item.serial
        ? `${item.serial} - ${item.name}`
        : item.name;
      const entity: ProductStore = {
        id: item.id,
        idProduct: item.idProduct,
        name: item.name,
        description,
        value: Number(item.value.toString().replace(",", ".")),
        serial: item.serial,
        amount: item.amount,
        maxdiscount: item.maxdiscount,
      };

      //todo: recuperar serial number
      return entity;
    });
    console.log("8888", products);
    return products;
  }
  return [];
};

const getByDescriptionForAutoComplete = async (
  filter: string
): Promise<any[]> => {
  if (filter.length > 2) {
    const credential = { ...store.state.login.credential };

    const list: Array<ProductStore> = (
      await axios.get(
        `${API_URL}/produtoconf/getproduto?cliente=${credential.tenantId}&filtro=${filter}`
      )
    ).data;

    const products: ProductStore[] = list.map((item: ProductStore) => {
      const description = item.serial
        ? `${item.serial} - ${item.name}`
        : item.name;
      const entity: ProductStore = {
        id: item.id,
        idProduct: item.idProduct,
        name: item.name,
        description,
        value: Number(item.value.toString().replace(",", ".")),
        serial: item.serial,
        amount: item.amount,
        maxdiscount: item.maxdiscount,
      };

      //todo: recuperar serial number
      return entity;
    });
    console.log("99999999", products);
    return products;
  }
  return [];
};

const insert = async (entity: Product): Promise<Product[]> => {
  return (await axios.post(`${API_URL}/material/product`, prepareData(entity)))
    .data;
};

const remove = async (id: string): Promise<Product[]> =>
  (await axios.delete(`${API_URL}/material/product/${id}`)).data;

const update = async (id: string, entity: Product): Promise<Product[]> => {
  return (
    await axios.put(`${API_URL}/material/product/${id}`, prepareData(entity))
  ).data;
};

const prepareData = (entity: Product) => {
  const credential = { ...store.state.login.credential };

  const updateEntity = {
    /* product */
    code: entity.code,
    productypeId: entity.productypeId,
    brandId: entity.brandId,
    userId: credential.userId,

    /* historic product */
    description: entity.description,
    ncm: entity.ncm,
    cest: entity.cest,
    ean: entity.ean,
    price: entity.price,
    discountinreais: entity.discountinreais,
    useserialnumber: entity.useserialnumber,
    movesstock: entity.movesstock,
    returnstostock: entity.returnstostock,
    note: entity.note,
    unitofmeasurementId: entity.unitofmeasurementId,
    productoriginId: entity.productoriginId,
    productgenreId: entity.productgenreId,

    /* historic product */
    conversionsunitofmeasure: entity.conversionsunitofmeasure,
  };

  return updateEntity;
};

export const ProductService = {
  getAll,
  get,
  getById,
  getByDescriptionSerialForAutoComplete,
  getByDescriptionForAutoComplete,
  insert,
  remove,
  update,
};
