import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Unit,
  UnitState,
} from "@/models/interfaces/administration/unit.interface";
import { UnitService as service } from "@/services/administration/unit.services";
import { constants } from "@/constants/administration/unit";

export const actions: ActionTree<UnitState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Unit[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_UNITS, res);
  },

  async getAllSchedule({ commit }): Promise<void> {
    const res: Unit[] = await service.getAllSchedule();
    commit(constants.SET_UNITS, res);
  },

  get({ commit }, id): void {
    service.get(id).then((res: Unit) => {
      commit(constants.SET_UNIT, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Unit[] = await service.insert(state.unit);
    commit(constants.INSERT_UNIT, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);
    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Unit[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_UNITS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Unit[] = await service.update(id, state.unit);
    commit(constants.UPDATE_UNIT, res);
  },
};
