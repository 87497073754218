import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  TransferAccount,
  TransferAccountState,
} from "@/models/interfaces/financial/transferaccount.interface";
import { TransferAccountService as service } from "@/services/financial/transferaccount.services";
import { constants } from "@/constants/financial/transferaccount";

export const actions: ActionTree<TransferAccountState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: TransferAccount[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_TRANSFERACCOUNTS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: TransferAccount) => {
      commit(constants.SET_TRANSFERACCOUNT, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: TransferAccount[] = await service.insert(state.transferAccount);
    commit(constants.INSERT_TRANSFERACCOUNT, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: TransferAccount[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_TRANSFERACCOUNTS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: TransferAccount[] = await service.update(
      id,
      state.transferAccount
    );
    commit(constants.UPDATE_TRANSFERACCOUNT, res);
  },
};
