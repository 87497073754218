import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "submit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "moreButtons"),
    _createVNode(_component_Button, {
      label: "Salvar",
      type: "submit",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save()))
    }),
    _createVNode(_component_Button, {
      label: "Cancelar",
      class: "acao-secundaria",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: `${_ctx.back}` })))
    })
  ]))
}