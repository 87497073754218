import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { WorkOrder } from "@/models/interfaces/commercial/workorder.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "ordemservico").fetchData();

const get = async (id: string): Promise<WorkOrder> => {
  if (id == "new") {
    const entity: WorkOrder = {
      id: "",
      serialnumber: "",
      newserialnumber: undefined,
      productdescription: "",
      productwarranty: false,
      expecteddate: undefined,
      deliverydate: undefined,
      serviceId: 0,
      storeId: 0,
      userId: 0,
      saleId: 0,
      ordernote: [],
      orderAuthorization: undefined,
      internalorder: undefined,
      customerorder: undefined,
      Serie: "",
      Descricao: "",
      Id: "",
      receiptcode: "",
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/ordemservico?id=${id}`)).data;
};

const insert = async (entity: WorkOrder): Promise<WorkOrder[]> => {
  return (
    await axios.post(`${API_URL}/ordemservico/Salvar`, prepareData(entity))
  ).data;
};

const remove = async (id: string): Promise<WorkOrder[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/ordemservico/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const update = async (id: string, entity: any): Promise<WorkOrder[]> => {
  const r = (
    await axios.post(`${API_URL}/ordemservico/Salvar`, prepareData(entity))
  ).data;

  if (entity.receiptcode) {
    const credential = { ...store.state.login.credential };

    const url =
      `${API_URL}/ordemservico/atualizarstatus?cliente=${credential.tenantId}` +
      `&id=${entity.Id}&codigo=${entity.receiptcode}&usuario=${credential.userId}`;

    const retorno = await axios.get(url);

    /*  if (entity.Obs) {
      entity.Obs += r.data;
    } else {
      entity.Obs = r.data;
    } */
  }

  return r;
};

const prepareData = (entity: any) => {
  const credential = { ...store.state.login.credential };

  let Obs = "";
  if (entity.Observacao) Obs = `${entity.Obs} <br/> ${entity.Observacao}`;
  else Obs = entity.Obs;

  const entitySave = {
    Id: entity.Id,
    Serie: entity.Serie,
    Descricao: entity.Descricao,
    Garantia: entity.Garantia,
    Autorizado: entity.Autorizado,
    Obs,
    Valor: entity.Valor,
    ClienteId: entity.personId ? entity.personId : entity.ClienteId,
    UnidadeId: credential.storeId,
    IdCliente: credential.tenantId,
    Usuario: credential.userId,
    Tipo: entity.Tipo,
    itens: [],
    DataCadastro: entity.DataCadastro,
    IdOrdemServico: entity.IdOrdemServico,
    StatusOs: entity.StatusOs,
    TrocarSerial: entity.TrocarSerial,
    NovaSerie: entity.NovaSerie,
  };

  return entitySave;
};

export const WorkorderService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
