import {
  ProductState,
  Product,
  ProductStore,
} from "@/models/interfaces/material/product.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ProductState> = {
  setProducts(state, payload: Product[]) {
    state.products = payload;
  },

  setProduct(state, payload: Product) {
    state.product = payload;
  },

  setProductsStore(state, payload: ProductStore[]) {
    state.productsStore = payload;
  },

  setProductStore(state, payload: ProductStore) {
    state.productStore = payload;
  },

  insertProduct(state, payload: Product) {
    state.product = payload;
  },

  removeProduct(state, payload: Product) {
    state.product = payload;
  },

  updateProduct(state, payload: Product) {
    state.product = payload;
  },
};
