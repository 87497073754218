import {
  CustomerState,
  Customer,
} from "@/models/interfaces/customer/customer.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<CustomerState> = {
  setCustomers(state, payload: Customer[]) {
    state.customers = payload;
  },

  setCustomer(state, payload: Customer) {
    state.customer = payload;
  },

  insertCustomer(state, payload: Customer) {
    state.customer = payload;
  },

  removeCustomer(state, payload: Customer) {
    state.customer = payload;
  },

  updateCustomer(state, payload: Customer) {
    state.customer = payload;
  },
};
