import { API_URL } from "@/constants/env";
import { Purchase } from "@/models/interfaces/material/purchase.interface";
import axios from "axios";
import store from "@/store";
import { useGetAll } from "@/composables/getall";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "compra").fetchData();

const get = async (id: string): Promise<Purchase> => {
  if (id == "new") {
    const entity: Purchase = {
      /* purchase */
      id: "",
      storeId: undefined,
      supplierId: undefined,
      note: "",
      purchasedate: "",
      userId: undefined,

      /* item */
      purchaseitems: [],
    };

    return entity;
  }

  const entity = (await axios.get(`${API_URL}/material/purchase/${id}`)).data;

  return entity;
};

const insert = async (entity: Purchase): Promise<Purchase[]> => {
  return (await axios.post(`${API_URL}/material/purchase`, prepareData(entity)))
    .data;
};

const remove = async (id: string): Promise<Purchase[]> =>
  (await axios.delete(`${API_URL}/material/purchase/${id}`)).data;

const update = async (id: string, entity: Purchase): Promise<Purchase[]> => {
  return (
    await axios.put(`${API_URL}/material/purchase/${id}`, prepareData(entity))
  ).data;
};

const prepareData = (entity: Purchase) => {
  const credential = { ...store.state.login.credential };

  const updateEntity = {
    /* purchase */
    storeId: credential.storeId,
    supplierId: entity.supplierId,
    note: entity.note,
    purchasedate: entity.purchasedate,
    userId: credential.userId,

    /* item */
    purchaseitems: entity.purchaseitems,
  };

  return updateEntity;
};

export const PurchaseService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
