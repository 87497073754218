import { CustomerService } from "@/services/customer/customer.services";

export const getRecipient = async (customerId: string) => {
  const customer: any = await CustomerService.getById(customerId);

  /* const cpfCnpj = customer.DocumentoNf.replace(/\D/g, ""); */

  if (customer.DocumentoNf) {
    return {
      cpfCnpj: customer.DocumentoNf.replace(/\D/g, ""),
      razaoSocial: customer.NomeNf,
      email: customer.Email ? customer.Email : "",
      endereco: {
        tipoLogradouro: "Rua",
        logradouro: customer.addressnf.street,
        numero: customer.addressnf.number,
        bairro: customer.addressnf.neighborhood,
        codigoCidade: customer.addressnf.ibgecode,
        descricaoCidade: customer.addressnf.cityName,
        estado: customer.addressnf.stateAcronym,
        cep: customer.addressnf.zipcode,
      },
      inscricaoEstadual: customer.InscricaoEstadualNf
        ? customer.InscricaoEstadualNf
        : "", //não é obrigatório
      inscricaoMunicipal: customer.InscricaoMunicipalNf
        ? customer.InscricaoMunicipalNf
        : "", //não é obrigatório
    };
  } else {
    return {
      cpfCnpj: customer.Cpf.replace(/\D/g, ""),
      razaoSocial: customer.Nome,
      email: customer.Email ? customer.Email : "",
      endereco: {
        tipoLogradouro: "Rua",
        logradouro: customer.address.street,
        numero: customer.address.number,
        bairro: customer.address.neighborhood,
        codigoCidade: customer.address.ibgecode,
        descricaoCidade: customer.address.cityName,
        estado: customer.address.stateAcronym,
        cep: customer.address.zipcode,
      },
      inscricaoEstadual: "", //não é obrigatório
      inscricaoMunicipal: "", //não é obrigatório
    };
  }
};
