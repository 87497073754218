import { ContactType } from "@/models/interfaces/shared/contacttype.interface";

export const constants = {
  SET_CONTACTTYPES: "setContactTypes",
  SET_CONTACTTYPE: "setContactType",
};

export const data: ContactType[] = [
  { id: "1", description: "Celular" },
  { id: "2", description: "Telefone Residencial" },
  /*   { id: "3", description: "Telefone Comercial" }, */
  /*  { id: "4", description: "Whatsapp" }, */
  { id: "5", description: "Email" },
  /* { id: "6", description: "Instagran" },
  { id: "7", description: "Linkedin" },
  { id: "8", description: "YouTube" },
  { id: "9", description: "Facebook" }, */
];
