import { useDate } from "@/composables/date";

export const getBankslip = async (sale: any) => {
  const quotas = await getQuota(sale.accountReceivable);

  return {
    numero: 1,
    valorTotal: quotas.total,
    valorDesconto: 0,
    valorLiquido: quotas.total,
    parcelas: quotas.quotas,
  };
};

const getQuota = async (accounts: any) => {
  const quotas = await accounts.reduce(
    async (accPromise: Promise<any>, quota: any) => {
      const acc = await accPromise; // Aguardar a resolução da promessa anterior

      acc.quotas.push({
        numero: acc.quotaNumber.toString().padStart(3, "0"),
        valor: quota.Valor,
        dataVencimento: useDate("pt-BR").formatDateForInvoice(
          quota.DataVencimentoFormatada
        ),
      });

      acc.total += quota.Valor;
      acc.quotaNumber++;

      return acc; // Retorna a promessa resolvida com o novo acumulador
    },
    Promise.resolve({ quotas: [], quotaNumber: 1, total: 0 }) // Inicializa como uma promessa resolvida
  );

  return quotas;
};
