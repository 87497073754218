import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Profile,
  ProfileState,
} from "@/models/interfaces/administration/profile.interface";
import { ProfileService as profile } from "@/services/administration/profile.services";
import { constants } from "@/constants/administration/profile";

export const actions: ActionTree<ProfileState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Profile[] = await profile.getAll(page, searchText, lastFilters);
    commit(constants.SET_PROFILES, res);
  },
  get({ commit }, id): void {
    profile.get(id).then((res: Profile) => {
      commit(constants.SET_PROFILE, res);
    });
  },
  async getAllbyTenant({ commit }): Promise<void> {
    const res: Profile[] = await profile.getAllbyTenant();
    commit(constants.SET_PROFILES, res);
  },

  async insert({ commit, state }): Promise<void> {
    const res: Profile[] = await profile.insert(state.profile);
    commit(constants.INSERT_PROFILE, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await profile.remove(ids);
    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Profile[] = await profile.getAll(page, searchText, lastFilters);

    commit(constants.SET_PROFILES, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Profile[] = await profile.update(id, state.profile);
    commit(constants.UPDATE_PROFILE, res);
  },
};
