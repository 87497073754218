import { API_URL } from "@/constants/env";
import {
  AccountPayable,
  InstallmentToPay,
} from "@/models/interfaces/financial/accountpayable.interface";
import axios from "axios";
import store from "@/store";
import { useGetAll } from "@/composables/getall";
import { data } from "@/constants/shared/contacttype";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "despesa").fetchData();

const get = async (id: string): Promise<AccountPayable> => {
  if (id == "new") {
    const entity: AccountPayable = {
      id: "",
      description: "",
      storeId: 0,
      financialcategoryId: 0,
      installmentId: 0,
      installments: [],
      installmenttopay: undefined,
      payments: [],
      personId: undefined,
      createddate: undefined,
      competencedate: "",
      duedate: undefined,
      value: undefined,
      isentry: false,
      supplierName: "",
      paymentValue: 0,
    };
    return entity;
  }

  const entity = (await axios.get(`${API_URL}/despesa?id=${id}`)).data;

  return treatedEntity(entity);
};

const getByNameForAutoCompletePayee = async (name: string): Promise<any[]> => {
  //todo: favorecido hoje é apenas contato, ele passara ser fornecedor, colaborador e cliente

  if (name.length > 2) {
    const credential = { ...store.state.login.credential };

    const list = (
      await axios.get(
        `${API_URL}/contato?cliente=${credential.tenantId}&pos=1&tipoRegistro=Ativo&filtro=${name}`
      )
    ).data;

    const payees: string[] = list.map((item: any) => {
      const entity = {
        id: item.Id,
        name: item.Nome,
        idCriptografado: item.IdCriptografado,
      };
      return entity;
    });

    return payees;
  }
  return [];
};

const treatedEntity = (entity: any): any => {
  if (entity.DataCompetenciaFormatada) {
    const parts = entity.DataCompetenciaFormatada.split("/");
    const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    entity.DataCompetenciaFormatada = new Date(isoDateString)
      .toISOString()
      .split("T")[0];
  }
  if (entity.DataVencimentoFormatada) {
    const parts = entity.DataVencimentoFormatada.split("/");
    const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    entity.DataVencimentoFormatada = new Date(isoDateString)
      .toISOString()
      .split("T")[0];
  }

  if (entity.DataPagamentoFormatada) {
    const parts = entity.DataPagamentoFormatada.split("/");
    const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    entity.DataPagamentoFormatada = new Date(isoDateString)
      .toISOString()
      .split("T")[0];
  }

  return entity;
};

const insert = async (entity: AccountPayable): Promise<AccountPayable[]> => {
  return (await axios.post(`${API_URL}/despesa/Salvar`, prepareData(entity)))
    .data;
};

const remove = async (id: string): Promise<AccountPayable[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/despesa/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};
const update = async (
  id: string,
  entity: AccountPayable
): Promise<AccountPayable[]> => {
  return (await axios.post(`${API_URL}/despesa/Salvar`, prepareData(entity)))
    .data;
};
const prepareData = (entity: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { Id, ...updateEntity } = entity;
  const credential = { ...store.state.login.credential };

  const id = Id ? Id : 0;

  const IdCliente = credential.tenantId;
  const Usuario = credential.userId;
  const UnidadeId = credential.storeId;
  const CaixaId = credential.cashier;
  const MeioPagamentoId = entity.MeioPagamentoId ? entity.MeioPagamentoId : 0;
  const ValorFormatado = entity.Valor;
  const ValorRecebidoFormatado = entity.ValorRecebido
    ? entity.ValorRecebido
    : 0;
  const AcrescimoFormatado = entity.Acrescimo ? entity.Acrescimo : 0;
  const DescontoFormatado = entity.Desconto ? entity.Desconto : 0;
  const CategoriaFinanceiraId = 1;
  const QtdeParcelas = entity.QtdeParcelas ? entity.QtdeParcelas : 1;

  /*   const RepasseId = 0;
  const IdAgenda = 0;

  const UsuarioFechouCaixa = 0;
  const LoteId = 0;
  const PlanoContaId = 1273;
  const CentroCustoId = 0;
  const ContaId = 0;
  const ContaDestinoId = 0;
  const ClienteId = 0;
  const ProfissionalId = 0;
  const ConvenioId = 0;
  const ProcedimentoId = 0;
  const PlanoAtendimentoId = 0;
  const CompraId = 0;
  const VendaId = 0;
  const OrdemServicoId = 0;
  const BoletoId = 0; */
  /*   const MeioPagamentoId = 579; */

  /* const DataVencimentoFormatada = "28/03/2024",
  "DataCompetenciaFormatada = "28/03/2024", */
  /*  const ContatoId = 0;
  const NfeId = 0; */

  return {
    ...updateEntity,
    Id: id,
    IdCliente,
    UnidadeId,
    MeioPagamentoId,
    CaixaId,
    Usuario,
    ValorFormatado,
    ValorRecebidoFormatado,
    AcrescimoFormatado,
    DescontoFormatado,
    CategoriaFinanceiraId,
    QtdeParcelas,
    /*    QtdeParcelas, */
  };
};

export const AccountPayableService = {
  getAll,
  getByNameForAutoCompletePayee,
  get,
  insert,
  remove,
  update,
};
