import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  DailyCashFlow,
  CashManagementState,
} from "@/models/interfaces/financial/cashmanagement.interface";
import { DailyCashFlowService as service } from "@/services/financial/cashManagement.services";
import { constants } from "@/constants/financial/cashmanagement";

export const actions: ActionTree<CashManagementState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: DailyCashFlow[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_CASHMANAGEMENTS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: DailyCashFlow) => {
      commit(constants.SET_CASHMANAGEMENT, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: DailyCashFlow[] = await service.insert(state.dailyCashFlow);
    commit(constants.INSERT_CASHMANAGEMENT, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: DailyCashFlow[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_CASHMANAGEMENTS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: DailyCashFlow[] = await service.update(id, state.dailyCashFlow);
    commit(constants.UPDATE_CASHMANAGEMENT, res);
  },
};
