import { API_URL } from "@/constants/env";
import { ProductType } from "@/models/interfaces/shared/producttype.interface";
import axios from "axios";

const getAll = async (): Promise<ProductType[]> => {
  const r = (await axios.get(`${API_URL}/shared/producttype`)).data;
  return r;
};

const get = async (id: string): Promise<ProductType> => {
  return (await axios.get(`${API_URL}/shared/producttype/${id}`)).data;
};

export const ProductTypeService = {
  getAll,
  get,
};
