import messages from "./messages";
import * as Yup from "yup";
import cpf from "./validation/cpf";

interface Validation {
  description: string;
  yup: any;
  field: (description: string) => Validation;
  required: () => Validation;
  email: () => Validation;
  cpf: (field: string) => Validation;
  getResult: () => string | boolean;
}

const validation: Validation = {
  description: "",
  yup: true,
  field(description: string) {
    this.description = description;
    this.yup = Yup.string();
    return this;
  },
  required(): any {
    this.yup = this.yup.required(
      messages.compile({ id: "required", value: this.description })
    );

    return this;
  },
  email(): any {
    this.yup = this.yup.email(messages.schema.email);
    return this;
  },
  cpf(): any {
    this.yup = cpf(this.yup);
    return this;
  },
  getResult() {
    return this.yup;
  },
};

const execute = (value: any, field: string, validation: any): any => {
  try {
    const schema = Yup.object().shape({
      [field]: validation,
    });
    const res = schema.validateSync({ [field]: value });
    return res;
  } catch (error: any) {
    return error.message;
  }

  return "";
};

const showError = (values: any, toast: any) => {
  const arrErros = Object.entries(values);

  let error;
  for (let i = 0; i < arrErros.length; i++) {
    if (arrErros[i][1]) {
      error = arrErros[i][1];
      break;
    }
  }

  if (!error)
    error =
      "Ocorreu um error interno. Se o error persistir favor entrar em contato com o suporte.";

  toast.add({
    /* severity: "error", */
    /* summary: "Excluir", */
    detail: error,
    life: 3000,
  });
};

export default { execute, validation, showError };
