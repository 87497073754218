import { RouteRecordRaw } from "vue-router";
import Invetory from "../../views/material/invetory/Index.vue";
import Invetories from "../../views/material/invetory/List.vue";
import Auth from "@/helpers/security/auth";

const itens: Array<RouteRecordRaw> = [];

itens.push({
  path: "/material/invetory/:id",
  name: "Invetory",
  component: Invetory,
  beforeEnter: Auth,
});
itens.push({
  path: "/material/invetory/list",
  name: "Invetories",
  component: Invetories,
  beforeEnter: Auth,
});

export default itens;
