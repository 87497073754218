import {
  Customer,
  CustomerState,
} from "@/models/interfaces/customer/customer.interface";

const customer: Customer = {
  /* physicalperson */
  id: "",
  Id: "",
  cpf: "",
  birthdate: undefined,

  /* historicphysicalperson */
  civilstatusId: undefined,
  professionId: undefined,
  genderId: undefined,
  personaltreatmentId: undefined,
  name: "",
  preferredname: "",
  anothergender: "",
  placeofbird: "",
  userId: undefined,

  /* customer */
  storeId: undefined,
  /*   active: true, */
  /* user */
  /* isuser: false,
  profileId: undefined,
  password: "", */
  /* person */
  finalconsumer: false,

  personnotes: [],

  contacts: [],

  address: {
    zipcode: "",
    state: undefined,
    city: "",
    neighborhood: "",
    street: "",
    id: "",
    ibgecode: undefined,
    stateId: undefined,
    stateAcronym: "",
    cityId: undefined,
    cityName: "",
    number: "",
    complement: "",
  },
  addressnf: {
    zipcode: "",
    state: undefined,
    city: "",
    neighborhood: "",
    street: "",
    id: "",
    ibgecode: undefined,
    stateId: undefined,
    stateAcronym: "",
    cityId: undefined,
    cityName: "",
    number: "",
    complement: "",
  },

  documents: [],

  DataNascimento: undefined,

  idCriptografado: "",
  idByCustomer: "",
};

const customers: Array<Customer> = [];

export const state: CustomerState = {
  customer,
  customers,
};
