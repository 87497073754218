import { API_URL } from "@/constants/env";
import { Inventory } from "@/models/interfaces/material/inventory.interface";
import axios from "axios";
import store from "@/store";
import { useGetAll } from "@/composables/getall";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "produtoconf").fetchData();

const get = async (id: string): Promise<Inventory> => {
  if (id == "new") {
    const entity: Inventory = {
      /* Inventory */
      id: "0",
      productId: undefined,
      storeId: undefined,
      unitofmeasurementId: undefined,
      amount: 0,
      oldamount: 0,
      serialnumber: "",
      userId: undefined,
      note: "",
      ownerinventoryId: undefined,
    };

    return entity;
  }

  const entity = (await axios.get(`${API_URL}/material/inventory/${id}`)).data;

  return entity;
};

const insert = async (entity: Inventory): Promise<Inventory[]> => {
  return (
    await axios.post(`${API_URL}/material/inventory`, prepareData(entity))
  ).data;
};

const remove = async (id: string): Promise<Inventory[]> =>
  (await axios.delete(`${API_URL}/material/inventory/${id}`)).data;

/* const update = async (id: string, entity: Inventory): Promise<Inventory[]> => { */
const update = async (id: string, entity: Inventory): Promise<Inventory[]> => {
  return (
    await axios.post(`${API_URL}/material/inventory`, prepareData(entity))
  ).data;
};

const prepareData = (entity: Inventory) => {
  const credential = { ...store.state.login.credential };

  const updateEntity = {
    /* Inventory */
    productId: entity.productId,
    storeId: credential.storeId,
    unitofmeasurementId: entity.unitofmeasurementId,
    amount: entity.amount,
    oldamount: entity.oldamount,
    serialnumber: entity.serialnumber,
    userId: credential.userId,
    stockadjustmentNote: entity.note,
  };

  return updateEntity;
};

export const InventoryService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
