import { API_URL } from "@/constants/env";
import { SALE_TYPE } from "@/helpers/filters";
import { SaleConsumer } from "@/models/interfaces/commercial/saleConsumer.interface";
import store from "@/store";
import axios from "axios";
import { getAll } from "./getall.service";

export const remove = async (id: string): Promise<SaleConsumer[]> => {
  const credential = { ...store.state.login.credential };
  await (
    await axios.delete(
      `${API_URL}/venda/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;

  const filterSaleType = SALE_TYPE;
  filterSaleType.model.value = 3;

  const r = await getAll(1, "", [[filterSaleType]]);

  return r;
};
