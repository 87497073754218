import store from "@/store";
import { getItems } from "./item";
import { getRecipient } from "./receipient.service";
import { getPayments } from "./payment.service";
import { getBankslip } from "./bankslip.service";
import { SaleService } from "../../sale";

export const prepareDataInvoice = async (entity: any) => {
  const credential = { ...store.state.login.credential };
  //Seu identificador único para a NFe
  const idIntegracao = entity.Id.toString();
  const natureza = entity.Natureza; //"VENDA";
  const emitente = { cpfCnpj: credential.storeCnpj.replace(/\D/g, "") };
  const consumidorFinal = entity.ConsumidorFinal; // true;
  const destinatario = await getRecipient(entity.ClienteIdCriptografado);

  const it = entity.nfeitem ? entity.nfeitem : entity.Itens;
  const itemsTreated = await getItems(it, destinatario, consumidorFinal);

  const itens = itemsTreated.itens;

  const invoice: { [k: string]: any } = {
    idIntegracao,
    natureza,
    emitente,
    destinatario,
    itens,
  };
  const sale: any = await SaleService.get(entity.VendaIdCriptografado);
  if (sale.Id) {
    invoice.pagamentos = await getPayments(sale);
    //invoice.cobranca = await getBankslip(sale);
  } else {
    const saleFake = {
      accountReceivable: [
        {
          MeioPagamentoTipo: entity.MeioPagamentoTipo,
          Valor: itemsTreated.total,
        },
      ],
    };
    invoice.pagamentos = await getPayments(saleFake);
  }

  const invoices = [];

  invoices.push(invoice);

  return invoices;
};
