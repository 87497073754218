import { useGetAll } from "@/composables/getall";
import { API_URL } from "@/constants/env";
import { Supplier } from "@/models/interfaces/material/supplier.interface";
import store from "@/store";
import axios from "axios";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "contato").fetchData();

const getAllSupplierCore = async (): Promise<Supplier[]> => {
  const credential = { ...store.state.login.credential };
  const r = (
    await axios.get(
      `${API_URL}/contato/TodosPorTipo?cliente=${credential.tenantId}&tipo=4`
    )
  ).data;

  return r;
};

const get = async (id: string): Promise<Supplier> => {
  if (id == "new") {
    const entity: Supplier = {
      /* legalperson */
      id: "",
      cnpj: "",

      /* historiclegalperson */
      corporatename: "",
      fantasyname: "",
      userId: 1,

      /* supplier */
      iscore: false,
      suppliertypes: [],

      /* person */
      finalconsumer: false,

      personnotes: [],

      contacts: [],

      addresses: [],

      documents: [],
    };

    return entity;
  }

  const entity = (await axios.get(`${API_URL}/material/supplier/${id}`)).data;

  const { iscore, finalconsumer, ...updateEntity } = entity;

  const treatedEntity = {
    ...updateEntity,
    iscore: iscore == 1,
    finalconsumer: finalconsumer == 1,
  };

  if (treatedEntity.personnotes?.length)
    treatedEntity.personnotes[0].show = true;

  return treatedEntity;
};

const insert = async (entity: Supplier): Promise<Supplier[]> => {
  return (await axios.post(`${API_URL}/material/supplier`, prepareData(entity)))
    .data;
};

const remove = async (id: string): Promise<Supplier[]> =>
  (await axios.delete(`${API_URL}/material/supplier/${id}`)).data;

const update = async (id: string, entity: Supplier): Promise<Supplier[]> => {
  return (
    await axios.put(`${API_URL}/material/supplier/${id}`, prepareData(entity))
  ).data;
};

const prepareData = (entity: Supplier) => {
  const updateEntity = {
    cnpj: entity.cnpj,
    corporatename: entity.corporatename,
    fantasyname: entity.fantasyname,
    userId: entity.userId,
    iscore: entity.iscore,
    suppliertypes: entity.suppliertypes,
    finalconsumer: entity.finalconsumer,
    personnotes: entity.personnotes,
    contacts: entity.contacts,
    addresses: entity.addresses,
    documents: entity.documents,
  };

  return updateEntity;
};

export const SupplierService = {
  getAll,
  getAllSupplierCore,
  get,
  insert,
  remove,
  update,
};
