
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "SaveBack",
    props: {
      back: String,
    },

    methods: {
      save() {
        this.$emit("save");
      },
    },
  });
