import { API_URL } from "@/constants/env";
import { UnitOfMeasurement } from "@/models/interfaces/shared/unitofmeansurement.interface";
import axios from "axios";

const getAll = async (): Promise<UnitOfMeasurement[]> => {
  const r = (await axios.get(`${API_URL}/shared/unitofmeasurement`)).data;
  return r;
};

const get = async (id: string): Promise<UnitOfMeasurement> => {
  return (await axios.get(`${API_URL}/shared/unitofmeasurement/${id}`)).data;
};

export const UnitOfMeasurementService = {
  getAll,
  get,
};
