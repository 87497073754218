import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Customer,
  CustomerState,
} from "@/models/interfaces/customer/customer.interface";
import { CustomerService as customer } from "@/services/customer/customer.services";
import { constants } from "@/constants/customer";
import store from "..";

export const actions: ActionTree<CustomerState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Customer[] = await customer.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_CUSTOMERS, res);
  },
  getById({ commit }, id): void {
    customer.getById(id).then((res: Customer) => {
      commit(constants.SET_CUSTOMER, res);
    });
  },
  getByNameForAutoComplete({ commit }, name): void {
    customer.getByNameForAutoComplete(name).then((res: Customer[]) => {
      commit(constants.SET_CUSTOMERS, res);
    });
  },

  async remove({ commit }, ids): Promise<void> {
    await customer.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Customer[] = await customer.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_CUSTOMERS, res);
  },

  async changeStatusActive({ commit }, ids): Promise<void> {
    await customer.changeStatusActive(ids);

    const browsing = { ...store.state.login.browsing };

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = browsing.lastFilters;
    const res: Customer[] = await customer.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_CUSTOMERS, res);
  },

  async insert({ commit, state }): Promise<void> {
    const entity: any = await customer.insert(state.customer);

    state.customer.id = entity.Id;
    state.customer.idByCustomer = entity.IdCliente;
    state.customer.name = entity.Nome;
    state.customer.idCriptografado = entity.IdCriptografado;

    /* commit(constants.INSERT_CUSTOMER); */
  },

  async update({ commit, state }, id): Promise<void> {
    await customer.update(id, state.customer);
    /*   commit(constants.UPDATE_CUSTOMER, entity); */
  },
};
