import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import { Test, TestState } from "@/models/interfaces/commercial/test.interface";
import { TestService as service } from "@/services/commercial/test.services";
import { constants } from "@/constants/commercial/test";

export const actions: ActionTree<TestState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Test[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_TESTS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Test) => {
      commit(constants.SET_TEST, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Test[] = await service.insert(state.test);
    commit(constants.INSERT_TEST, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Test[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_TESTS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Test[] = await service.update(id, state.test);
    commit(constants.UPDATE_TEST, res);
  },
};
