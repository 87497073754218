import messages from "@/helpers/validate/messages";
import * as yup from "yup";

export default yup.object({
  customer: yup
    .object()
    .test(
      "customer",
      messages.compile({ id: "required", value: "Cliente" }),
      (value: any) => {
        return value ? !!value.id : !!value;
      }
    ),

  /*   employee: yup
    .number()
    .test(
      "employee",
      messages.compile({ id: "required", value: "Colaborador" }),
      (value: any) => {
        return !!value;
      }
    ), */

  saleitem: yup
    .array()
    .test(
      "saleitem",
      messages.compile({ id: "requiredList", value: "de itens da venda" }),
      (value: any) => {
        return value ? !!value.length : !!value;
      }
    ),

  accountReceivable: yup
    .array()
    .test(
      "accountReceivable",
      messages.compile({ id: "requiredList", value: "de contas a receber" }),
      (value: any) => {
        return value ? !!value.length : !!value;
      }
    ),
  financialBalance: yup
    .number()
    .test(
      "financialBalance",
      "A soma das parcelas não está de acordo com o valor da venda.",
      value => {
        return value === 0;
      }
    ),

  financialaccount: yup
    .number()
    .typeError(messages.compile({ id: "required", value: "Conta" }))
    .test(
      "financialaccount",
      messages.compile({ id: "required", value: "Conta" }),
      (value: any) => {
        return !!value;
      }
    ),
});
