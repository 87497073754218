import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Treatment,
  TreatmentState,
} from "@/models/interfaces/commercial/treatment.interface";
import { TreatmentService as treatment } from "@/services/commercial/treatment.services";
import { constants } from "@/constants/commercial/treatment";

export const actions: ActionTree<TreatmentState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Treatment[] = await treatment.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_TREATMENTS, res);
  },

  get({ commit }, id): void {
    treatment.get(id).then((res: Treatment) => {
      commit(constants.SET_TREATMENT, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: Treatment[] = await treatment.insert(state.treatment);
    commit(constants.INSERT_TREATMENT, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await treatment.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Treatment[] = await treatment.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_TREATMENTS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Treatment[] = await treatment.update(id, state.treatment);
    commit(constants.UPDATE_TREATMENT, res);
  },
};
