import { API_URL } from "@/constants/env";
import { Customer } from "@/models/interfaces/customer/customer.interface";
import axios from "axios";
import store from "@/store";
import { useGetAll } from "@/composables/getall";
import { Contact } from "@/models/interfaces/shared/contact.interface";
import { Document } from "@/models/interfaces/shared/document.interface";
import { Address } from "@/models/interfaces/shared/address.interface";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "paciente").fetchData();

const getByNameForAutoComplete = async (name: string): Promise<any[]> => {
  if (name.length > 2 || Number(name)) {
    const credential = { ...store.state.login.credential };
    const list = (
      await axios.get(
        `${API_URL}/paciente?cliente=${credential.tenantId}&pos=1&tipoRegistro=Ativo&filtro=${name}`
      )
    ).data;

    const customers: string[] = list.map((item: any) => {
      const entity = {
        id: item.Id,
        idbycustomer: item.IdporClienteNotePro,
        name: item.Nome,
        description: `${item.IdporClienteNotePro} - ${item.Nome}`,
        scheduled: null,
        idCriptografado: item.IdCriptografado,
      };
      return entity;
    });
    /*     {  "id": 2459019, "status": 1 } */

    return customers;
  }
  return [];
};

const getById = async (id: string): Promise<Customer> => {
  if (id == "new") {
    const entity: Customer = {
      /* physicalperson */
      id: "",
      Id: "",
      cpf: "",
      birthdate: undefined,

      /* historicphysicalperson */
      civilstatusId: undefined,
      professionId: undefined,
      genderId: undefined,
      personaltreatmentId: undefined,
      name: "",
      preferredname: "",
      anothergender: "",
      placeofbird: "",
      userId: undefined,

      /* employee */
      storeId: undefined,
      /*   active: true, */
      /* user */
      /* isuser: false,
      profileId: undefined,
      password: "", */
      /* person */
      finalconsumer: false,

      personnotes: [],

      contacts: [],

      address: {
        zipcode: "",
        state: undefined,
        city: "",
        neighborhood: "",
        street: "",
        id: "",
        ibgecode: undefined,
        stateId: undefined,
        stateAcronym: "",
        cityId: undefined,
        cityName: "",
        number: "",
        complement: "",
      },

      addressnf: {
        zipcode: "",
        state: undefined,
        city: "",
        neighborhood: "",
        street: "",
        id: "",
        ibgecode: undefined,
        stateId: undefined,
        stateAcronym: "",
        cityId: undefined,
        cityName: "",
        number: "",
        complement: "",
      },

      documents: [],

      DataNascimento: undefined,
      idCriptografado: "",
      idByCustomer: "",
    };

    return entity;
  }

  const idCustomer = id ? id : 0;

  const entity = (await axios.get(`${API_URL}/paciente?id=${idCustomer}`)).data;

  return treatedEntity(entity);
};

const treatedEntity = (entity: any): Customer => {
  const {
    finalconsumer,
    birthdate,
    TelefoneResidencial,
    TelefoneCelular,
    Email,
    CarteiraIdentidade,
    CartaoNacionalSaude,

    Cep,
    UfId,
    UfSigla,
    CidadeId,
    CidadeNome,
    CidadeIbge,
    Bairro,
    Logradouro,
    Numero,
    Complemento,

    CepNf,
    UfNfId,
    UfNfSigla,
    CidadeNfId,
    CidadeNfNome,
    CidadeNfIbge,
    BairroNf,
    LogradouroNf,
    NumeroNf,
    ComplementoNf,

    ...updateEntity
  } = entity;

  const contacts: Contact[] = [];

  if (TelefoneResidencial)
    contacts.push({
      description: TelefoneResidencial,
      contacttypeId: 2,
      contacttypeDescription: "Telefone Residencial",
      id: "2",
    });
  if (TelefoneCelular)
    contacts.push({
      description: TelefoneCelular,
      contacttypeId: 1,
      contacttypeDescription: "Celular",
      id: "1",
    });
  if (Email)
    contacts.push({
      description: Email,
      contacttypeId: 5,
      contacttypeDescription: "Email",
      id: "5",
    });

  const documents: Document[] = [];

  if (CarteiraIdentidade)
    documents.push({
      description: CarteiraIdentidade,
      documenttypeId: 1,
      documenttypeDescription: "Carteira de identidade",
      id: "1",
    });

  if (CartaoNacionalSaude)
    documents.push({
      description: CartaoNacionalSaude,
      documenttypeId: 2,
      documenttypeDescription: "Carteira nacional da saúde",
      id: "2",
    });

  const address: Address = {
    zipcode: Cep,
    state: UfId,
    city: CidadeId,
    neighborhood: Bairro,
    street: Logradouro,
    id: "1",
    ibgecode: CidadeIbge,
    stateId: UfId,
    stateAcronym: UfSigla,
    cityId: CidadeId,
    cityName: CidadeNome,
    number: Numero,
    complement: Complemento,
  };

  const addressnf: Address = {
    zipcode: CepNf,
    state: UfNfId,
    city: CidadeNfId,
    neighborhood: BairroNf,
    street: LogradouroNf,
    id: "2",
    ibgecode: CidadeNfIbge,
    stateId: UfNfId,
    stateAcronym: UfNfSigla,
    cityId: CidadeNfId,
    cityName: CidadeNfNome,
    number: NumeroNf,
    complement: ComplementoNf,
  };

  const treatedEntity = {
    ...updateEntity,
    /* isuser: isNumber(isuser), */
    finalconsumer: finalconsumer == 1,
    birthdate: new Date(birthdate),
    contacts,
    documents,
    address,
    addressnf,
  };

  if (treatedEntity.DataNascimentoFormatada)
    treatedEntity.DataNascimentoFormatada = new Date(entity.DataNascimento)
      .toISOString()
      .split("T")[0];

  if (treatedEntity.DataValidadeConvenioFormatada)
    treatedEntity.DataValidadeConvenioFormatada = new Date(
      entity.DataValidadeConvenio
    )
      .toISOString()
      .split("T")[0];

  if (treatedEntity.personnotes?.length)
    treatedEntity.personnotes[0].show = true;

  return treatedEntity;
};
const remove = async (id: string): Promise<Customer[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/paciente/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

const changeStatusActive = async (id: string): Promise<Customer[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.get(
      `${API_URL}/paciente/mundastatusai?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};

/* const isNumber = (value: any) => typeof value === "number"; */

const url = `${API_URL}/paciente/Salvar`;

const insert = async (entity: Customer): Promise<Customer[]> => {
  const r = (await axios.post(url, prepareData(entity))).data;

  return r;
};

/* return (await axios.put(`${API_URL}/customer/${id}`, prepareData(entity)))
    .data; */
const update = async (id: string, entity: Customer) => {
  return (await axios.post(url, prepareData(entity))).data;
};

const prepareData = (entity: Customer) => {
  const credential = { ...store.state.login.credential };

  const {
    Id,
    birthdate,
    finalconsumer,
    contacts,
    documents,
    address,
    addressnf,
    cpf,
    ...updateEntity
  } = entity;

  const id = Id ? Id : 0;

  const IdCliente = credential.tenantId;
  const usuario = credential.userId;

  const saveContact = {
    TelefoneResidencial: "",
    TelefoneCelular: "",
    Email: "",
  };
  for (let i = 0; i < contacts.length; i++) {
    const c = contacts[i];
    if (c.id == 1) saveContact.TelefoneCelular = c.description;
    if (c.id == 2) saveContact.TelefoneResidencial = c.description;
    if (c.id == 5) saveContact.Email = c.description;
  }

  const saveDocument = {
    CarteiraIdentidade: "",
    CartaoNacionalSaude: "",
  };
  for (let i = 0; i < documents.length; i++) {
    const d = documents[i];
    if (d.id == "1") saveDocument.CarteiraIdentidade = d.description;
    if (d.id == "2") saveDocument.CartaoNacionalSaude = d.description;
  }

  const saveEntity = {
    ...updateEntity,

    Id: id,
    IdCliente,
    usuario,

    /*  Cpf: cpf ? cpf : null, */

    Cep: address?.zipcode,
    UfId: address?.stateId,
    CidadeId: address?.cityId,
    Bairro: address?.neighborhood,
    Logradouro: address?.street,
    Numero: address?.number,
    Complemento: address?.complement,

    CepNf: addressnf?.zipcode,
    UfNfId: addressnf?.stateId,
    CidadeNfId: addressnf?.cityId,
    BairroNf: addressnf?.neighborhood,
    LogradouroNf: addressnf?.street,
    NumeroNf: addressnf?.number,
    ComplementoNf: addressnf?.complement,

    TelefoneResidencial: saveContact.TelefoneResidencial,
    TelefoneCelular: saveContact.TelefoneCelular,
    Email: saveContact.Email,

    CarteiraIdentidade: saveDocument.CarteiraIdentidade,
    CartaoNacionalSaude: saveDocument.CartaoNacionalSaude,
  };

  return saveEntity;
};

/* const parseDate = (date: string | undefined) => {
  if (!date) return undefined;
  // Divide a data original em partes usando "/"
  const partes = date.split("/");

  // Reorganiza as partes no formato ISO 8601
  return partes[2] + "-" + partes[1] + "-" + partes[0];
}; */

export const CustomerService = {
  getAll,
  getById,
  getByNameForAutoComplete,
  insert,
  remove,
  update,
  changeStatusActive,
};
