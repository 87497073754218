import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Voucher,
  VoucherState,
} from "@/models/interfaces/commercial/voucher.interface";
import { VoucherService as service } from "@/services/commercial/voucher";
import { constants } from "@/constants/commercial/voucher";

export const actions: ActionTree<VoucherState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Voucher[] = await service.getAll(page, searchText, lastFilters);
    commit(constants.SET_VOUCHERS, res);
  },
  get({ commit }, id): void {
    service.get(id).then((res: Voucher) => {
      commit(constants.SET_VOUCHER, res);
    });
  },
  async redeem({ commit }, { id, customer }): Promise<void> {
    const res = await service.redeem(id, customer);
    commit(constants.SET_VOUCHER, res);
    /*  commit(constants.SET_VOUCHER, {
      id: "",
      discount: res,
      codevoucher: id,
      customerId: customer,
    }); */
  },
  async insert({ commit, state }): Promise<void> {
    const res: Voucher[] = await service.insert(state.voucher);
    commit(constants.INSERT_VOUCHER, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Voucher[] = await service.getAll(page, searchText, lastFilters);

    commit(constants.SET_VOUCHERS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: Voucher[] = await service.update(id, state.voucher);
    commit(constants.UPDATE_VOUCHER, res);
  },

  async setVoucher({ commit, state }, id): Promise<void> {
    const res: Voucher[] = await service.setVoucher(id, state.voucher);
    commit(constants.UPDATE_VOUCHER, res);
  },
};
