import {
  Supplier,
  SupplierState,
} from "@/models/interfaces/material/supplier.interface";

const supplier: Supplier = {
  /* legalperson */
  id: "",
  cnpj: "",

  /* historiclegalperson */
  corporatename: "",
  fantasyname: "",
  userId: undefined,

  /* supplier */
  iscore: false,
  suppliertypes: [],

  /* person */
  finalconsumer: false,

  personnotes: [],

  contacts: [],

  addresses: [],

  documents: [],
};

const suppliers: Array<Supplier> = [];

export const state: SupplierState = {
  supplier,
  suppliers,
};
