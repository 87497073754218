import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  FinancialAccount,
  FinancialAccountMeansOfPayment,
  FinancialAccountState,
} from "@/models/interfaces/financial/financialAccount.interface";
import { FinancialAccountService as service } from "@/services/financial/financialAccount.services";
import { constants } from "@/constants/financial/financialaccount";

export const actions: ActionTree<FinancialAccountState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: FinancialAccount[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_FINANCIALACCOUNTS, res);
  },
  /*   getAllWithMeansofpayments({ commit }): void {
    service.getAllWithMeansofpayments().then((res: FinancialAccount[]) => {
      commit(constants.SET_FINANCIALACCOUNTS, res);
    });
  }, */
  getAllWithMeansofpayments({ commit }): void {
    service
      .getAllWithMeansofpayments()
      .then((res: FinancialAccountMeansOfPayment[]) => {
        commit(constants.SET_FINANCIALACCOUNTS, res);
      });
  },

  async getAllByTenant({ commit }): Promise<void> {
    await service
      .getAllByTenant()
      .then((res: FinancialAccountMeansOfPayment[]) => {
        commit(constants.SET_FINANCIALACCOUNTS, res);
      });
  },
  async insert({ commit, state }): Promise<void> {
    const res: FinancialAccount[] = await service.insert(
      state.financialAccount
    );
    commit(constants.INSERT_FINANCIALACCOUNT, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await service.remove(ids);

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: FinancialAccount[] = await service.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_FINANCIALACCOUNTS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: FinancialAccount[] = await service.update(
      id,
      state.financialAccount
    );
    commit(constants.UPDATE_FINANCIALACCOUNT, res);
  },
};
