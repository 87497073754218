import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  PerformingPhysician,
  PerformingPhysicianState,
} from "@/models/interfaces/billing/performingphysician.interface";
import { PerformingPhysicianService as performingPhysician } from "@/services/billing/performingphysician.services";
import { constants } from "@/constants/billing/performingphysician";

export const actions: ActionTree<PerformingPhysicianState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: PerformingPhysician[] = await performingPhysician.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_PERFORMINGPHYSICIANS, res);
  },
  get({ commit }, id): void {
    performingPhysician.get(id).then((res: PerformingPhysician) => {
      commit(constants.SET_PERFORMINGPHYSICIAN, res);
    });
  },
  async insert({ commit, state }): Promise<void> {
    const res: PerformingPhysician[] = await performingPhysician.insert(
      state.performingPhysician
    );
    commit(constants.INSERT_PERFORMINGPHYSICIAN, res);
  },
  async remove({ commit }, ids): Promise<void> {
    await performingPhysician.remove(ids);
    const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: PerformingPhysician[] = await performingPhysician.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_PERFORMINGPHYSICIANS, res);
  },
  async update({ commit, state }, id): Promise<void> {
    const res: PerformingPhysician[] = await performingPhysician.update(
      id,
      state.performingPhysician
    );
    commit(constants.UPDATE_PERFORMINGPHYSICIAN, res);
  },
};
