import { API_URL } from "@/constants/env";
import { ProductOrder } from "@/models/interfaces/material/productorder.interface";
import axios from "axios";
import store from "@/store";
import { useGetAll } from "@/composables/getall";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "pedido").fetchData();

const get = async (id: string): Promise<ProductOrder> => {
  if (id == "new") {
    const entity: ProductOrder = {
      id: "0",
      note: "",
      userId: undefined,
      /*   userName: "",
      userPreferredname: "", */
      storeId: undefined,
      /*  productorderId: undefined,
      createddate: "",
      amountSum: undefined,
      amountacceptedSum: undefined,
      amountacceptedCount: undefined,
      inventoryCreateddate: "", */
      productorderitems: [],
    };
    return entity;
  }

  return (await axios.get(`${API_URL}/material/productorder/${id}`)).data;
};

const insert = async (entity: ProductOrder): Promise<ProductOrder[]> => {
  return (
    await axios.post(`${API_URL}/material/productorder`, prepareData(entity))
  ).data;
};

const remove = async (id: string): Promise<ProductOrder[]> =>
  (await axios.delete(`${API_URL}/material/productorder/${id}`)).data;

const update = async (
  id: string,
  entity: ProductOrder
): Promise<ProductOrder[]> => {
  return (
    await axios.put(
      `${API_URL}/material/productorder/${id}`,
      prepareData(entity)
    )
  ).data;
};

const prepareData = (entity: ProductOrder) => {
  const credential = { ...store.state.login.credential };

  const updateEntity = {
    /* product order */

    note: entity.note,
    userId: credential.userId,
    storeId: credential.storeId,

    /* item */
    productorderitems: entity.productorderitems,
  };

  return updateEntity;
};

const stockTransfer = async (items: any): Promise<ProductOrder[]> => {
  const selectedItems: string[] = items.map((item: { id: any }) => item.id);
  const credential = { ...store.state.login.credential };

  return (
    await axios.patch(
      `${API_URL}/material/productorder/stocktransfer/${credential.storeId}/${credential.userId}/${selectedItems}`
    )
  ).data;
};

export const ProductOrderService = {
  getAll,
  get,
  insert,
  remove,
  update,
  stockTransfer,
};
