<template>
  <Menu class="no-print"></Menu>
  <main>
    <router-view name="sidebar"></router-view>
    <router-view></router-view>
  </main>

  <!--  <Chat class="no-print"></Chat> -->
</template>

<script>
  import Menu from "@/components/menu/Menu.vue";
  /*  import Chat from "@/components/chat/Chat.vue"; */

  export default {
    components: {
      Menu,
    },
  };
</script>
<style scoped>
  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
  }
</style>
