import {
  Inventory,
  InventoryState,
} from "@/models/interfaces/material/inventory.interface";

const inventory: Inventory = {
  /* inventory */
  id: "0",
  productId: undefined,
  storeId: undefined,
  unitofmeasurementId: undefined,
  amount: 0,
  oldamount: 0,
  serialnumber: "",
  userId: undefined,
  note: "",
  ownerinventoryId: undefined,
};

const inventories: Array<Inventory> = [];

export const state: InventoryState = {
  inventory,
  inventories,
};
