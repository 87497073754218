import {
  ProductOrder,
  ProductOrderState,
} from "@/models/interfaces/material/productorder.interface";

const productOrder: ProductOrder = {
  id: "0",
  note: "",
  userId: undefined,
  /*  userName: "",
  userPreferredname: "", */
  storeId: undefined,
  /*  productorderId: undefined,
  createddate: "",
  amountSum: undefined,
  amountacceptedSum: undefined,
  amountacceptedCount: undefined,
  inventoryCreateddate: "", */

  productorderitems: [],
};

const productOrders: Array<ProductOrder> = [];

export const state: ProductOrderState = {
  productOrder,
  productOrders,
};
