import { API_BANKSLIP_URL, API_URL } from "@/constants/env";
import {
  AccountReceivable,
  Installment,
} from "@/models/interfaces/financial/accountreceivable.interface";
import axios from "axios";
import store from "@/store";
import { useGetAll } from "@/composables/getall";
import { data } from "@/constants/shared/contacttype";

import { END_DATE } from "@/helpers/fields";
import { newBankslip } from "./account/newbankslip.service";
import { prepareData } from "./account/prepareData";

const getAll = async (
  page: number,
  searchText: string,
  lastFilters: any[]
): Promise<any[]> =>
  useGetAll(page, searchText, lastFilters, "receita").fetchData();

const get = async (id: string): Promise<AccountReceivable> => {
  if (id == "new") {
    const entity: AccountReceivable = {
      id: "",
      description: "",
      storeId: 0,
      financialcategoryId: 0,
      installmentId: 0,
      installments: [],
      installmentreceivable: undefined,
      receipts: [],
      Invoice: "",
    };
    return entity;
  }

  const entity = (await axios.get(`${API_URL}/receita?id=${id}`)).data;

  return treatedEntity(entity);
};

const treatedEntity = (entity: any): any => {
  if (entity.DataCompetenciaFormatada) {
    const parts = entity.DataCompetenciaFormatada.split("/");
    const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    entity.DataCompetenciaFormatada = new Date(isoDateString)
      .toISOString()
      .split("T")[0];
  }
  if (entity.DataVencimentoFormatada) {
    const parts = entity.DataVencimentoFormatada.split("/");
    const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    entity.DataVencimentoFormatada = new Date(isoDateString)
      .toISOString()
      .split("T")[0];
  }

  if (entity.DataPagamentoFormatada) {
    const parts = entity.DataPagamentoFormatada.split("/");
    const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    entity.DataPagamentoFormatada = new Date(isoDateString)
      .toISOString()
      .split("T")[0];
  }

  if (entity.DataCadastroFormatada) {
    const parts = entity.DataCadastroFormatada.split("/");
    const isoDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
    entity.DataCadastroFormatada = new Date(isoDateString)
      .toISOString()
      .split("T")[0];
  }

  return entity;
};

const insert = async (entity: any): Promise<AccountReceivable[]> => {
  if (entity.newBankslip) newBankslip(entity);

  return (await axios.post(`${API_URL}/receita/Salvar`, prepareData(entity)))
    .data;
};

const remove = async (id: string): Promise<AccountReceivable[]> => {
  const credential = { ...store.state.login.credential };
  return (
    await axios.delete(
      `${API_URL}/receita/apagar?ids=${id}&usuario=${credential.userId}`
    )
  ).data;
};
const update = async (
  id: string,
  entity: any
): Promise<AccountReceivable[]> => {
  entity.Invoice = await createBankslip(entity);

  const data = prepareData(entity);
  return (await axios.post(`${API_URL}/receita/Salvar`, data)).data;
};

const createBankslip = async (entity: any) => {
  debugger;
  if (entity.newBankslip && !entity.Invoice) {
    const bankslip = await newBankslip(entity);

    if (bankslip) {
      try {
        const response = await axios.post(
          `${API_BANKSLIP_URL}/boletos/unicred`,
          bankslip
        );

        return response.data;
      } catch (ex: any) {
        console.log("erro ao emitir boleto", ex);
        entity.Erro = ex.response.data.body.reduce(
          (resultado: string, item: any) => {
            resultado += `${item.message};`;
            return resultado;
          },
          `${ex.response.data.message}: `
        );
      }
    }
  }
  return entity.Invoice;
};

export const AccountReceivableService = {
  getAll,
  get,
  insert,
  remove,
  update,
};
